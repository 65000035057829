import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

import Home from "../Home/Home";
import About from "../About/About";
import Benefits from "../Benefits/Benefits";
import CoursesNew from "../Courses/CoursesNew";
import Coaches from "../Coaches/Coaches";
import Contact from "../Contact/Contact";
import { BsWhatsapp } from "react-icons/bs";

import "../../Nav.css";
import Testimonials from "../../features/testimonials/index";

const Landing = () => {

  useEffect(() => {
    Aos.init({
      duration: 800,
      offset: 0,
    });
  }, []);

  return (
    <section style={{ margin: 0, padding: 0, boxSizing: 'border-box', overflow: 'hidden' }}>
      <Home />
      <Benefits />
      <About />
      <CoursesNew />
      <Coaches />
      <Testimonials />
      <Contact />
      <div className="chat-menu">
        <BsWhatsapp
          onClick={() =>
            window.open(
              "https://wa.me/917448408684?text=Hey%20Young%20Visionaries.%20I%27m%20interested%20to%20avail%20your%20services.",
              "_blank"
            )
          }
          size={30}
          style={{ color: "green" }} // Change the color to blue
        />
      </div>
    </section>
  );
};

export default Landing;
