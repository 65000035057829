import React, { useState } from "react";

import "../../testimonials.css";
// import swiper react components
import { Swiper, SwiperSlide } from "swiper/react";

import woman1 from "../../assets/img/woman.png";
import woman2 from "../../assets/img/woman2.png";

// import swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./slider.css";
import TestimonialCard from "../../components/TestimonialCard";
// import required modules
import { Pagination } from "swiper";

const RatingStars = ({ count }) => {
  const stars = Array.from({ length: count }, (_, index) => (
    <svg
      key={index}
      className="w-5 h-5 text-[#FDB241]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  ));

  return <div className="flex items-center">{stars}</div>;
};

export default function Testimonials() {
  const [cardOpt, setCardOpt] = useState(0);

  let studentData = [
    {
      name: "Michael Schrute",
      title: " Student - Grade 11",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },

    {
      name: "Jenny Wilson",
      title: " Student - Grade 6",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },

    {
      name: "Jacob Jones",
      title: " Student - Grade 10",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },
  ];

  return (
    <section
      id="testimonials"
      data-aos="fade-up"
      className="py-12 bg-gray-50 sm:py-16 lg:py-20"
    >
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center">
          <div className="text-center">
            <h2 className="mt-4 text-3xl font-bold text-primary sm:text-4xl xl:text-5xl font-pj">
              Valuable feedback from our satisfied clients
            </h2>

            {/* <div className="flex flex-row mt-10 justify-center gap-20">
              <button
                class="relative inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group"
                onClick={() => setCardOpt(1)}
              >
                <span
                  class={`absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out  ${
                    cardOpt == 1 ? "bg-white" : "bg-primary"
                  } rounded-md group-hover:mt-0 group-hover:ml-0`}
                ></span>
                <span
                  class={`absolute inset-0 w-full h-full  ${
                    cardOpt == 1 ? "bg-primary" : "bg-white"
                  } rounded-md `}
                ></span>
                <span class="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-primary rounded-md opacity-0 group-hover:opacity-100 "></span>
                <span
                  class={`relative ${
                    cardOpt == 1 ? "text-white" : "text-primary"
                  } transition-colors duration-200 ease-in-out delay-100 group-hover:text-white`}
                >
                  Students
                </span>
              </button>

              <button
                class="relative inline-flex items-center justify-center px-6 py-3 text-lg font-medium tracking-tighter text-white bg-gray-800 rounded-md group"
                onClick={() => setCardOpt(2)}
              >
                <span
                  class={`absolute inset-0 w-full h-full mt-1 ml-1 transition-all duration-300 ease-in-out ${
                    cardOpt == 2 ? "bg-white" : "bg-primary"
                  } rounded-md group-hover:mt-0 group-hover:ml-0`}
                ></span>
                <span
                  class={`absolute inset-0 w-full h-full ${
                    cardOpt == 2 ? "bg-primary" : "bg-white"
                  } rounded-md`}
                ></span>
                <span class="absolute inset-0 w-full h-full transition-all duration-200 ease-in-out delay-100 bg-primary rounded-md opacity-0 group-hover:opacity-100 "></span>
                <span
                  class={`relative ${
                    cardOpt == 2 ? "text-white" : "text-primary"
                  } transition-colors duration-200 ease-in-out delay-100 group-hover:text-white`}
                >
                  Parents
                </span>
              </button>
            </div> */}
          </div>
          {cardOpt == 1 && (
            <div className="relative mt-10 md:mt-24 md:order-2">
              <div className="absolute  -inset-x-1 inset-y-12 md:-inset-x-2 md:-inset-y-6">
                {/* <div
                  className="w-4/6 h-4/6 max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                  style={{
                    background:
                      "linear-gradient(90deg, #E5B8F4 -0.55%, #E5B8F4 22.86%, #E5B8F4 48.36%, #E5B8F4 73.33%, #E5B8F4 99.34%)",
                  }}
                /> */}
              </div>
              <div className="relative w-4/6 grid max-w-md grid-cols-1 gap-2 mx-auto md:max-w-none lg:gap-10 md:grid-cols-1">
                {studentData.length > 0 ? (
                  <Swiper
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    spaceBetween={20}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {studentData.map((obj, index) => {
                      <div
                        className="swiper-slide"
                        data-index={index}
                        key={index}
                      >
                        <TestimonialCard
                          name={obj.name}
                          avatar={obj.avatar}
                          comment={obj.comment}
                          title={obj.title}
                        />
                      </div>;
                    })}
                  </Swiper>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}

          {cardOpt == 2 && (
            <div className="relative mt-10 md:mt-24 md:order-2">
              {/* <div className="absolute inset-0">
              <div
                className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                style={{
                  background:
                    "linear-gradient(90deg, #E5B8F4 -0.55%, #E5B8F4 22.86%, #E5B8F4 48.36%, #E5B8F4 73.33%, #E5B8F4 99.34%)",
                }}
              />
            </div> */}
              <div className="relative w-full max-w-lg mx-auto lg:gap-10">
                <Swiper
                  slidesPerView={"auto"}
                  centeredSlides={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="flex flex-col overflow-hidden shadow-xl max-w-xs mx-auto sm:max-w-full md:max-w-full lg:max-w-full xl:max-w-full md:max-w-200 lg:max-w-200 xl:max-w-200">
                      <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                        <div className="flex-1">
                          <div className="flex items-center">
                            <RatingStars count={5} />
                          </div>
                          <blockquote className="flex-1 mt-8">
                            <p className="text-lg leading-relaxed text-gray-900 font-pj">
                              “You made it so simple. My new site is so much
                              faster and easier to work with than my old site. I
                              just choose the page, make the change.”
                            </p>
                          </blockquote>
                        </div>
                        <div className="flex items-center mt-8">
                          <img
                            className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                            src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png"
                            alt=""
                          />
                          <div className="ml-4">
                            <p className="text-base font-bold text-gray-900 font-pj">
                              Parent1
                            </p>
                            <p className="mt-0.5 text-sm font-pj text-gray-600">
                              Parent of abc
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col overflow-hidden shadow-xl max-w-xs mx-auto sm:max-w-full md:max-w-full lg:max-w-full xl:max-w-full md:max-w-200 lg:max-w-200 xl:max-w-200">
                      <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                        <div className="flex-1">
                          <div className="flex items-center">
                            <RatingStars count={5} />
                          </div>
                          <blockquote className="flex-1 mt-8">
                            <p className="text-lg leading-relaxed text-gray-900 font-pj">
                              “Simply the best. Better than all the rest. I’d
                              recommend this product to beginners and advanced
                              users.”
                            </p>
                          </blockquote>
                        </div>
                        <div className="flex items-center mt-8">
                          <img
                            className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                            src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png"
                            alt=""
                          />
                          <div className="ml-4">
                            <p className="text-base font-bold text-gray-900 font-pj">
                              Jacob Jones
                            </p>
                            <p className="mt-0.5 text-sm font-pj text-gray-600">
                              Parent of abc
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="flex flex-col overflow-hidden shadow-xl max-w-xs mx-auto sm:max-w-full md:max-w-full lg:max-w-full xl:max-w-full md:max-w-200 lg:max-w-200 xl:max-w-200">
                      <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                        <div className="flex-1">
                          <div className="flex items-center">
                            <RatingStars count={5} />
                          </div>
                          <blockquote className="flex-1 mt-8">
                            <p className="text-lg leading-relaxed text-gray-900 font-pj">
                              “I cannot believe that I have got a brand new
                              landing page after getting Omega. It was super
                              easy to edit and publish.”
                            </p>
                          </blockquote>
                        </div>
                        <div className="flex items-center mt-8">
                          <img
                            className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                            src="https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png"
                            alt=""
                          />
                          <div className="ml-4">
                            <p className="text-base font-bold text-gray-900 font-pj">
                              Jenny Wilson
                            </p>
                            <p className="mt-0.5 text-sm font-pj text-gray-600">
                              Parent of abc
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          )}

          {cardOpt == 0 && (
            <div className="w-3/6 relative mt-10 md:mt-24 md:order-2">
              {/* <div className="absolute inset-0">
              <div
                className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                style={{
                  background:
                    "linear-gradient(90deg, #E5B8F4 -0.55%, #E5B8F4 22.86%, #E5B8F4 48.36%, #E5B8F4 73.33%, #E5B8F4 99.34%)",
                }}
              />
            </div> */}
              <div className="lg:gap-10">
                <Swiper
                  slidesPerView={"auto"}
                  centeredSlides={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                >
                  <SwiperSlide>
                    <div className=" p-6 bg-white lg:py-8 lg:px-7 w-full h-full">
                      <div className="flex-1">
                        <div className="flex items-center">
                          <RatingStars count={5} />
                        </div>
                        <blockquote className="flex-1 mt-8">
                          <p className="text-lg leading-relaxed italic text-gray-700">
                            “Thanks a lot for establishing a positive classroom
                            vibe. Encouraged two-way communication in all the
                            classes. My daughter enjoyed and learned from each
                            and every session, including the punishments. Your
                            way of coaching provided her with a lot of
                            confidence and professionalism towards chess. Thank
                            you once again from the sincere upcoming champion.”
                          </p>
                        </blockquote>
                      </div>
                      <div className="flex items-center mt-8">
                        <img
                          className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                          src={woman2}
                          alt=""
                        />
                        <div className="ml-4">
                          <p className="text-base font-bold text-gray-800 font-pj">
                            Parent
                          </p>
                          <p className="mt-0.5 text-sm font-pj text-gray-600">
                            Mother of Jovisha Narayanan
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="p-6 bg-white  w-full h-full">
                      <div className="flex-1">
                        <div className="flex items-center">
                          <RatingStars count={5} />
                        </div>
                        <blockquote className="flex-1 mt-8">
                          <p className="text-lg leading-relaxed italic text-gray-700">
                            “My son Sid has been associated with coach Rathish
                            since 6 year old , and his journey, specially with
                            Rathish sir, is superb. Rathish sir understand my
                            child very well. He takes care whether he is
                            thorough with his concept or not .He is very
                            cooperative and understanding.”
                          </p>
                        </blockquote>
                      </div>
                      <div className="flex items-center mt-8">
                        <img
                          className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                          src={woman1}
                          alt=""
                        />
                        <div className="ml-4">
                          <p className="text-base font-bold text-gray-900 font-pj">
                            Gayathri
                          </p>
                          <p className="mt-0.5 text-sm font-pj text-gray-600">
                            Mother of Sid
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
