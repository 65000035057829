import React from "react";
import { useState } from "react";
import { Box } from "@mui/material";

import Axios from "axios";
import Swal from "sweetalert2";

import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const BookClass = ({ sessionID, currClass, courseID }) => {
  const [classData, setClassData] = useState({
    courseId: courseID,
    classDate: currClass.classDate,
    classDuration: currClass.duration,
  });

  // console.log(classData)
  const submitFeedback = async (e) => {
    e.preventDefault();

    try {

      let response = null
      if(currClass.edit) {
        response = await Axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/instructor/editClass/${currClass.classID}`,
          classData
        );
      } else {
        response = await Axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/instructor/createClass`,
          classData
        );
      }
      
      if (response.status == 201) {
        await Swal.fire({
          icon: "success",
          title: "Class Created/Updated!",
        });
        // setFeedbackData(false);
      }
    } catch (err) {
      if (!err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        justifyItems: "center",
        height: "60%",
        width: "60%",
        backgroundColor: "white",
        overflowY: "scroll"
      }}
      className="rounded-lg flex flex-col gap-2 pl-20 pb-20  pt-20"
    >
      <span class="text-4xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl font-bold text-primary font-primary">
        Create Class
      </span>
      <br />
      <div className="w-3/6">
        <label
          for="first_name"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Session ID
        </label>
        <input
          type="text"
          id="name"
          class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-0 focus:ring-primary focus:border-primary block w-full p-2.5"
          value={sessionID}
          disabled
          onChange={(e) =>
            setClassData((curr) => {
              return { ...curr, [e.target.id]: e.target.value };
            })
          }
        />
      </div>

      <div className="w-3/6">
        <label
          for="first_name"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Date
        </label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={classData.classDate}
            id="classDate"
            onChange={(newValue) =>
              setClassData((curr) => {
                return { ...curr, classDate: newValue };
              })
            }
            disablePast={true}
          />
        </LocalizationProvider>
      </div>
      <div className="w-3/6">
        <label
          for="first_name"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Duration (in min)
        </label>
        <input
          type="text"
          id="classDuration"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-0 focus:ring-primary focus:border-primary block w-full p-2.5 "
          placeholder=""
          onChange={(e) =>
            setClassData((curr) => {
              return { ...curr, [e.target.id]: e.target.value };
            })
          }
          value={classData.classDuration}
        />
      </div>
      <button
        className="rounded-md font-inter bg-primary text-white w-32 h-10 hover:bg-accent-primary_hover font-bold hover:text-white p-1"
        onClick={(e) => submitFeedback(e)}
      >
        Submit
      </button>
    </Box>
  );
};

export default BookClass;
