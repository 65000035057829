import React, { useState } from "react";
import { Avatar } from "@mui/material";
import Axios from "axios";
import Swal from "sweetalert2";
import validator from "validator";
import Loading from "../components/loading";
import { Modal } from "@mui/material";
import { NoEncryption } from "@mui/icons-material";

const AddAdmin = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [usrType, setUsrType] = useState("");

  const addAdmin = async (e) => {
    e.preventDefault();

    try {
      let check = false;

      if (
        userData.name.length > 0 &&
        validator.isEmail(userData.email) &&
        validator.isNumeric(userData.phone) &&
        userData.phone.length == 10 &&
        (usrType === 'Admin' || usrType === 'Instructor')
      )
        check = true;

      if (!check) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Invalid/Missing Input",
        });
        return;
      }

      setLoadingOpen(true);
      let response = await Axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/add${usrType}`,
        userData,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );

      if (response.status == 200) {
        setLoadingOpen(false);
        await Swal.fire({
          icon: "success",
          title: "Admin Created Successfully",
        });
      }
    } catch (err) {
      setLoadingOpen(false);
      if (!err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  return (
    <div className="bg-[#fbf7ff] w-full min-h-full flex flex-col  items-center p-10">
      <div className="flex flex-col w-5/6 gap-24">
        <div className=" inline-flex flex-row justify-between">
          <span className=" self-center text-7xl font-bold text-primary font-primary">
            Add
            <br />
            User
          </span>

          <div></div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <label
              for="name"
              className="text-primary font-primary text-[18px] font-semibold"
            >
              Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Name"
              className="w-2/6 h-8 appearance-none bg-white focus:border-black focus:ring-0 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              value={userData.name}
              onChange={(e) =>
                setUserData((curr) => {
                  return { ...curr, [e.target.id]: e.target.value };
                })
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <label
              for="email"
              className="text-primary font-primary text-[18px] font-semibold"
            >
              Email
            </label>
            <input
              id="email"
              type="text"
              placeholder="Email"
              className="w-2/6 h-8 appearance-none bg-white focus:border-black focus:ring-0 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              value={userData.email}
              onChange={(e) =>
                setUserData((curr) => {
                  return { ...curr, [e.target.id]: e.target.value };
                })
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <label
              for="phone"
              className="text-primary font-primary text-[18px] font-semibold"
            >
              Phone
            </label>
            <input
              id="phone"
              type="tel"
              className="w-2/6 h-8 appearance-none bg-white focus:border-slate-400 focus:ring-0 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              placeholder="Phone number"
              value={userData.phone}
              onChange={(e) =>
                setUserData((curr) => {
                  return { ...curr, [e.target.id]: e.target.value };
                })
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <label
              for="phone"
              className="text-primary font-primary text-[18px] font-semibold"
            >
              Password
            </label>
            <input
              id="password"
              type="text"
              className="w-2/6 h-8 appearance-none bg-white focus:border-slate-400 focus:ring-0 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              placeholder="Password"
              value={userData.password}
              onChange={(e) =>
                setUserData((curr) => {
                  return { ...curr, [e.target.id]: e.target.value };
                })
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <label
              for="phone"
              className="text-primary font-primary text-[18px] font-semibold"
            >
              User type
            </label>
            <select
              id="usertype"
              class="w-2/6 h-10 appearance-none bg-white focus:border-slate-400 focus:ring-0 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              onChange={(e) => setUsrType(e.target.value)}
            >
              <option value="NULL" selected>User type</option>
              <option value="Admin">Admin</option>
              <option value="Instructor">Instructor</option>
            </select>
          </div>
          {/* <div className="flex flex-row justify-center gap-5 mb-5">
            <button
              className={`${
                usrType == 1
                  ? "bg-white text-primary "
                  : "text-white bg-primary "
              } w-40 border-primary border-2 hover:bg-accent-tertiary font-bold py-2 px-4 rounded`}
              onClick={() => {
                setUsrType(1);
              }}
            >
              Parents
            </button>
            <button
              className={`${
                usrType == 2
                  ? "bg-white text-primary "
                  : "text-white bg-primary "
              } w-40 border-primary border-2 hover:bg-accent-tertiary font-bold py-2 px-4 rounded`}
              onClick={() => {
                setUsrType(2);
              }}
            >
              Instructors
            </button>
          </div> */}
          <div className="flex flex-row justify-center mt-10">
            <button
              className="rounded-md font-inter bg-primary text-white w-32 h-10 hover:bg-accent-primary_hover font-bold hover:text-white"
              onClick={(e) => addAdmin(e)}
            >
              Create User
            </button>
          </div>

          {/* <div className="flex flex-col gap-2">
            <label for="name" className="text-primary font-primary text-[18px] font-semibold">Name</label>
            <input id="name" type="text" className="w-2/6 h-8 appearance-none focus:border-black focus:ring-0 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none"/>
            </div> */}
        </div>
      </div>
      <Modal open={loadingOpen}>
        <Loading />
      </Modal>
    </div>
  );
};

export default AddAdmin;
