import React, { useEffect, useContext } from "react";
import Module from "../../features/studentDetails/components/Module";
import AdminScrollBar from "./AdminScroll";
import "../../StudentDetails.css";
import { useState } from "react";

import Swal from "sweetalert2";
import Axios from "axios";
import Modal from "@mui/material/Modal";
import Loading from "../../components/loading";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

import DropDowm from "../../components/Input/DropDown";
import { Box } from "@mui/material";
import { ParentContext } from "../../context/ParentContext";

const ViewClassHistory = () => {
  const { userType } = useAppContext();

  const [selectedModule, setSelectedModule] = useState(1);
  const [loadingOpen, setLoadingOpen] = useState(true);
  const [classHistory, setClassHistory] = useState();

  const { state } = useLocation();
  
  let student = 0;



  const parentState = useContext(ParentContext);

  if (userType === "parent") {
    student = parentState.currentStudent
  } else if (state) {
    student = state.student
  }

  const [pickCourseOpen, setPickCourseOpen] = useState(false);

  const [courseData, setCourseData] = useState([]);
  const [courseID, setCourseID] = useState(null);

  useEffect(() => {
    // setCurrStudent(student)
    if (courseID == null) {
      fetchCourseID();
    }
    // fetchHistory();
  }, []);

  const fetchCourseID = async () => {
    try {
      setLoadingOpen(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/${userType}/courseDetails/${student}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );

      if (response.status == 201) {
        setCourseData(response.data.result);
      }
      setPickCourseOpen(true);
    } catch (err) {
      if (!err.response) {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };
  const fetchHistory = async () => {
    try {
      setLoadingOpen(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/${userType}/classHistory/${courseID}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );
      setLoadingOpen(false);

      if (response.status == 201) {
        setClassHistory(response.data.result);
      }
    } catch (err) {
      setLoadingOpen(false);

      if (!err.response) {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  return (
    <div className="bg-[#fbf7ff] w-full min-h-full flex flex-col  items-center p-10">
      <div className="flex flex-col w-5/6 gap-24">
        <div className=" inline-flex flex-row justify-between">
          <span className=" self-center text-7xl font-bold text-primary font-primary">
            Class
            <br />
            History
          </span>
        </div>
        <div className="flex flex-col w-full items-center">
          <div className=" w-full">
            {loadingOpen? <></> : (<AdminScrollBar
              setSelectedModule={setSelectedModule}
              studentID={courseID}
              utype={userType}
            />)}
          </div>
          {loadingOpen ? (
            <></>
          ) : (
            <Module data={classHistory[selectedModule - 1]} utype={userType} courseID={courseID}/>
          )}
        </div>
      </div>

      <Modal open={loadingOpen}>
        <Loading />
      </Modal>

      {pickCourseOpen && (
        <Modal
          open={pickCourseOpen}
          onClose={() => setPickCourseOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              justifyItems: "center",
              height: "30%",
              width: "30%",
              backgroundColor: "white",
            }}
            className="rounded-lg flex flex-col gap-2 pl-20 pb-20  pt-20"
          >
            <span class="text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl font-bold text-primary font-primary">
              Select course
            </span>
            <br />

            <div className="w-3/6">
              <DropDowm data={courseData} handleDropChange={setCourseID} />
            </div>

            <button
              className="mt-6 w-24 text-white bg-primary  hover:bg-accent-secondary_hover font-bold py-2 px-4 rounded"
              onClick={() => {
                setPickCourseOpen(false)
                fetchHistory()
              }}
            >
              Confirm
            </button>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ViewClassHistory;
