import React from "react";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const MyGrid = ({data}) => {

  if (data.length == 0) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No data found",
    });
  }

  const createObj = (name) => {
    return {field: name}
  }

  let columns = Object.keys(data[0]);
  let columnDefs = columns.map((name) => createObj(name))


  return (
    <div className="ag-theme-alpine" style={{height: "600px", width: "100%"}}>
      <AgGridReact rowData={data} columnDefs={columnDefs}></AgGridReact>
    </div>
  );
};

export default MyGrid;
