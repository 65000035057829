import React, {useState} from 'react'
import { Navigate , Outlet} from 'react-router-dom'

function Protected({ reqType, children }) {

  const [isSignedIn, setIsSignedIn] = useState(sessionStorage.getItem("token"));
  const [userType, setUserType] = useState(sessionStorage.getItem("utype"));


  if (isSignedIn == undefined || !isSignedIn || userType != reqType) {
    return <Navigate to="/err-404" replace />
  } 
  return <Outlet />
}
export default Protected