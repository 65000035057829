import React from "react";
import { useState } from "react";
import { Box } from "@mui/material";

import Axios from "axios";
import Swal from "sweetalert2";

const Feedback = () => {
  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    phnum: "",
    message: "",
  });

  const submitFeedback = async (e) => {
    e.preventDefault();

    try {
      let response = await Axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/feedback/submit`,
        feedbackData
      );
      if (response.status == 201) {
        await Swal.fire({
          icon: "success",
          title: "Thanks for the Feedback!",
        });
        // setFeedbackData(false);
      }
    } catch (err) {
      if (!err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        justifyItems: "center",
        height: "60%",
        width: "60%",
        backgroundColor: "white",
        overflowY: "scroll"
        
      }}
      className="rounded-lg flex flex-col gap-2 pl-20 pb-20  pt-20"
    >
      <div className="w-full  h-full">
      <span class="text-4xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl font-bold text-primary font-primary">
        Submit Feedback
      </span>
      <br />
      <div className="w-3/6">
        <label
          for="first_name"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          class="bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-0 focus:ring-primary focus:border-primary block w-full p-2.5"
          placeholder=""
          required
          onChange={(e) =>
            setFeedbackData((curr) => {
              return { ...curr, [e.target.id]: e.target.value };
            })
          }
          value={feedbackData.name}
        />
      </div>
      <div className="w-3/6">
        <label
          for="first_name"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Email
        </label>
        <input
          id="email"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-0 focus:ring-primary focus:border-primary block w-full p-2.5 "
          placeholder=""
          onChange={(e) =>
            setFeedbackData((curr) => {
              return { ...curr, [e.target.id]: e.target.value };
            })
          }
          value={feedbackData.email}
        />
      </div>
      <div className="w-3/6">
        <label
          for="first_name"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Mobile Number
        </label>
        <input
          type="text"
          id="phnum"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-0 focus:ring-primary focus:border-primary block w-full p-2.5 "
          placeholder=""
          onChange={(e) =>
            setFeedbackData((curr) => {
              return { ...curr, [e.target.id]: e.target.value };
            })
          }
          value={feedbackData.phnum}
        />
      </div>
      <div className="w-3/6">
        <label
          for="message"
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          Feedback message
        </label>
        <textarea
          id="message"
          rows="4"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:ring-0 focus:ring-primary focus:border-primary "
          placeholder="Write your message..."
          onChange={(e) =>
            setFeedbackData((curr) => {
              return { ...curr, [e.target.id]: e.target.value };
            })
          }
          value={feedbackData.message}
        ></textarea>
      </div>
      <button
        className="rounded-md font-inter bg-primary text-white w-32 h-10 hover:bg-accent-primary_hover font-bold hover:text-white p-1"
        onClick={(e) => submitFeedback(e)}
      >
        Submit
      </button>
      </div>
     
    </Box>
  );
};

export default Feedback;
