import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

import Landing from "./components/Landing/Landing";
import AdminDashboard from "./pages/AdminDashboard";
import NotFound from "./components/Login/components/NotFound";
import Protected from "./components/Login/components/Protection";
import InstructorDashboard from "./pages/InstructorDashboard";
import ParentDashboard from "./pages/ParentDashboard";
import Navbar from "./components/Navbar/Navbar";
import ViewSignUp from "./components/ViewSignUp";
import ViewReq from "./components/ViewReq";
import InstructorProfile from "./pages/profile/InstructorProfile";
import AdminProfile from "./pages/profile/AdminProfile";
import ParentProfile from "./pages/profile/ParentProfile";

import CourseComp from "./features/studentDetails/CourseComp";
import CourseProgress from "./pages/CourseProgress";
import ClassHistory from "./pages/ClassHistory";
import Schedule from "./pages/Schedule";
import AddAdmin from "./pages/AddAdmin";
import AddStudent from "./pages/AddStudent";

import LoginContextLayout from "./context/LoginContextLayout";
import ParentContextLayout from "./context/ParentContextLayout";
import { AppContextProvider } from "./context/AppContext";
import SidenavTheme from "./matXapp/components/MatxTheme/SidenavTheme/SidenavTheme";
import Layout1Sidenav from "./matXapp/components/MatxLayout/Layout1/Layout1Sidenav";
import { useEffect, useRef, memo } from "react";
import {
  ThemeProvider,
  useMediaQuery,
  Box,
  styled,
  useTheme,
} from "@mui/material";

import { sidenavCompactWidth, sideNavWidth } from "./matXapp/utils/constant";
import useSettings from "./matXapp/hooks/useSettings";
import ManageIns from "./pages/manageIns/ManageIns";
import ManageStu from "./pages/manageIns/ManageStu";
import ViewClassHistory from "./pages/manageIns/ViewClassHistory";
import ViewCourseProgress from "./pages/manageIns/ViewCourseProgress";

const App = () => {
  const [userType, setUserType] = useState(sessionStorage.getItem("utype"));

  Aos.init({
    duration: 800,
    offset: 0,
  });

  const { settings, updateSettings } = useSettings();
  const { layout1Settings, secondarySidebar } = settings;
  const topbarTheme = settings.themes[layout1Settings.topbar.theme];
  const {
    leftSidebar: { mode: sidenavMode, show: showSidenav },
  } = layout1Settings;

  const getSidenavWidth = () => {
    switch (sidenavMode) {
      case "full":
        return sideNavWidth;

      case "compact":
        return sidenavCompactWidth;

      default:
        return "0px";
    }
  };

  const sidenavWidth = getSidenavWidth();
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const ref = useRef({ isMdScreen, settings });
  const layoutClasses = `theme-${theme.palette.type}`;

  useEffect(() => {
    let { settings } = ref.current;
    let sidebarMode = settings.layout1Settings.leftSidebar.mode;
    if (settings.layout1Settings.leftSidebar.show) {
      let mode = isMdScreen ? "close" : sidebarMode;
      updateSettings({ layout1Settings: { leftSidebar: { mode } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMdScreen]);

  return (
    <AppContextProvider>
      <div className="relative">
        <div>
          {/* <Navbar utype={userType} /> */}
          {showSidenav && sidenavMode !== "close" && (
            <SidenavTheme>
              <Layout1Sidenav />
            </SidenavTheme>
          )}
        </div>
        <div className="right h-full font-primary">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/err-404" element={<NotFound />} />

            <Route path="/admin" element={<Protected reqType={"admin"} />}>
              <Route path="home" element={<AdminDashboard />} />
              <Route path="demo-request" element={<ViewReq />} />
              <Route path="registration" element={<ViewSignUp />} />
              <Route path="profile" element={<AdminProfile />} />
              <Route path="addUser" element={<AddAdmin />} />
              <Route path="manage-ins" element={<ManageIns />} />
              <Route path="manage-student" element={<ManageStu />} />
              <Route path="class-history" element={<ViewClassHistory />} />
              <Route path="course-progress" element={<ViewCourseProgress />} />
            </Route>

            <Route
              path="/instructor"
              element={<Protected reqType={"instructor"} />}
            >
              <Route path="home" element={<InstructorDashboard />} />
              <Route path="profile" element={<InstructorProfile />} />
              <Route path="manage-student" element={<ManageStu />} />
              <Route path="course-progress" element={<ViewCourseProgress />} />
              <Route path="class-history" element={<ViewClassHistory />} />
            </Route>

            <Route path="/parent" element={<Protected reqType={"parent"} />}>
              <Route element={<ParentContextLayout />}>
                <Route path="home" element={<ParentDashboard />} />
                <Route path="profile" element={<ParentProfile />} />
                <Route path="progress" element={<ViewCourseProgress />} />
                <Route path="history" element={<ViewClassHistory />} />
                <Route path="schedule" element={<Schedule />} />
                <Route path="addStudent" element={<AddStudent />} />
                <Route path="coursecomp" element={<CourseComp />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </AppContextProvider>
  );
};

export default App;
