import { Fragment } from 'react';
import Scrollbar from 'react-perfect-scrollbar';
import { styled } from '@mui/material';
import { MatxVerticalNav } from '../components';
import useSettings from '../hooks/useSettings';
import { navigations } from '../navigations';
import { useAppContext } from '../../context/AppContext';

const StyledScrollBar = styled(Scrollbar)(() => ({
  paddingLeft: '1rem',
  paddingRight: '1rem',
  position: 'relative'
}));

const SideNavMobile = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100vw',
  background: 'rgba(0, 0, 0, 0.54)',
  zIndex: -1,
  [theme.breakpoints.up('lg')]: { display: 'none' }
}));


const menuData = {
  parent: [
    {name: "Dashboard", path: "/parent/home", icon: 'home'},
    {name: "Profile", path: "/parent/profile", icon: 'account_circle'},
    {name: "My Schedule", path: "/parent/schedule", icon: 'calendar_month'},
    {name: "Progress", path: "/parent/progress", icon: 'pending'},
    {name: "Class History", path: "/parent/history", icon: 'history'},
    {name: "Log Out", path: "/", icon: 'logout'},
  ],
  instructor: [
    {name: "Dashboard", path: "/instructor/home", icon: 'home'},
    {name: "Profile", path: "/instructor/profile", icon: 'account_circle'},
    {name: "My Schedule", path: "/instructor/home", icon: 'calendar_month'},
    {name: "My Progress", path: "/instructor/home", icon: 'pending'},
    {name: "Class History", path: "/instructor/home", icon: 'history'},
    {name: "Log Out", path: "/", icon: 'logout'},
  ],
  admin: [
    {name: "Dashboard", path: "/admin/home", icon: 'home'},
    {name: "Profile", path: "/admin/profile", icon: 'account_circle'},
    {name: "Demo Requests", path: "/admin/demo-request", icon: 'mail'},
    {name: "Registered Users", path: "/admin/registration", icon: 'group'},
    {name: "Add User", path: "/admin/addUser", icon: 'person_add'},
    {name: "Log Out", path: "/", icon: 'logout'},
  ],
};



const Sidenav = ({ children }) => {

  // Import Context

  const { isSignedIn, setIsSignedIn, userType, userName } = useAppContext();


  let finalNavigations = navigations

  if (isSignedIn) {

    let userMenu = {
      name: userName,
      icon: 'person',
      children: menuData[userType],
      // children: [
      //   { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
      //   { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
      //   { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
      //   { name: 'Error', iconText: '404', path: '/session/404' }
      // ]
    }

    finalNavigations = [userMenu, { label: '', type: 'label' }, { label: '', type: 'label' }, { label: '', type: 'label' }].concat(navigations)

  }


  const { settings, updateSettings } = useSettings();
  const updateSidebarMode = (sidebarSettings) => {
    let activeLayoutSettingsName = settings.activeLayout + 'Settings';
    let activeLayoutSettings = settings[activeLayoutSettingsName];

    updateSettings({
      ...settings,
      [activeLayoutSettingsName]: {
        ...activeLayoutSettings,
        leftSidebar: {
          ...activeLayoutSettings.leftSidebar,
          ...sidebarSettings
        }
      }
    });
  };

  return (
    <Fragment>
      <StyledScrollBar options={{ suppressScrollX: true }}>
        {children}
        <MatxVerticalNav items={finalNavigations} />
      </StyledScrollBar>

      <SideNavMobile onClick={() => updateSidebarMode({ mode: 'close' })} />
    </Fragment>
  );
};

export default Sidenav;
