// import icons
import {
  FaEnvelope
} from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";
import { AiFillHome } from "react-icons/ai";
import {
  FaChessKnight,
  FaBook,
  FaChess,
  FaAward,
} from "react-icons/fa";
import {
  BsFillFileEarmarkBarGraphFill,
  BsFillTelephoneFill,
} from "react-icons/bs";

// import images
import AboutImg from "../src/assets/img/about.png";
import Benefit1Img from "../src/assets/img/benefits/benefit1.png";
import Benefit2Img from "../src/assets/img/benefits/benefit2.png";
import Benefit3Img from "../src/assets/img/benefits/benefit3.png";
import Benefit4Img from "../src/assets/img/benefits/benefit4.png";
import RathishImg from "../src/assets/img/coaches/Rathish.png";
import SamarkshImg from "./assets/img/coaches/samarksh.png"
import LakshayImg from "../src/assets/img/coaches/lakshay.png"

import Testi1Img from "../src/assets/img/coaches/testi1.png";
import Logop3 from "../src/assets/img/logop3.png";
import Home from "../src/assets/img/home.png";
import Bgimg from "../src/assets/img/bg.png";
import Benefit1BgImg from "../src/assets/img/benefits/benefit1_bg.png";
import Benefit2BgImg from "../src/assets/img/benefits/benefit2_bg.png";
import Benefit3BgImg from "../src/assets/img/benefits/benefit3_bg.png";
import Benefit4BgImg from "../src/assets/img/benefits/benefit4_bg.png";
import memory from "./assets/lottie/memory.json"
import confidence from "./assets/lottie/confidence.json"
import creative from './assets/lottie/creative.json'
import problem from './assets/lottie/problem.json'


export const navigationData = [
  { name: "Home", link: "/#home", icon: AiFillHome },
  { name: "Why Chess?", link: "/#whychess", icon: FaChess },
  { name: "About Us", link: "/#about", icon: FaChessKnight },

  { name: "Courses", link: "/#courses", icon: FaBook },
  {
    name: "Coaches",
    link: "/#coaches",
    icon: BsFillFileEarmarkBarGraphFill,
  },
  { name: "Testimonials", link: "/#testimonials", icon: FaAward },
];

export const menuData = {
  parent: [
    {name: "Dashboard", route: "/parent/home", isLogout: false},
    {name: "Profile", route: "/parent/profile", isLogout: false},
    {name: "My Schedule", route: "/parent/schedule", isLogout: false},
    {name: "My Progress", route: "/parent/progress", isLogout: false},
    {name: "Class History and Feedback", route: "/parent/history", isLogout: false},
    {name: "Log Out", route: "/", isLogout: true},
  ],
  instructor: [
    {name: "Dashboard", route: "/instructor/home", isLogout: false},
    {name: "Profile", route: "/instructor/profile", isLogout: false},
    {name: "My Schedule", route: "/instructor/home", isLogout: false},
    {name: "My Progress", route: "/instructor/home", isLogout: false},
    {name: "Class History and Feedback", route: "/instructor/home", isLogout: false},
    {name: "Log Out", route: "/", isLogout: true},
  ],
  admin: [
    {name: "Dashboard", route: "/admin/home", isLogout: false},
    {name: "Profile", route: "/admin/profile", isLogout: false},
    {name: "Demo Requests", route: "/admin/demo-request", isLogout: false},
    {name: "Registered Users", route: "/admin/registration", isLogout: false},
    {name: "Add Admin", route: "/admin/addAdmin", isLogout: false},
    {name: "Log Out", route: "/", isLogout: true},
  ],
};
export const homeData = {
  title: ` Checkmate your boredom with our Chess classes!`,
  subtitle: "Experience hybrid classes (online and offline)",
  book: "Book a free demo",
  login: "Login",
  image: Home,
  bgimg: Bgimg,
  logo: Logop3,
};

export const aboutData = {
  image: AboutImg,
  title: "Find Out A Little More About Us",
  subtitle:
    "Our platform is specifically designed to help children learn how to play chess in a fun and engaging way. Our platform offers a variety of resources to help kids learn the game, including interactive lessons, puzzles, and practice exercises. Our team is made up of experienced chess coaches who specialize in teaching children. We understand that kids learn best when they are having fun, so we have designed our platform to be both entertaining and educational.",
};

export const benefitsData = {
  title: "Why Chess?",
  subtitle1: "Chess isn't just a game, it's a brain workout!",
  subtitle2:
    "Besides being a fun game, playing chess has many benefits for the brain.",
  list: [
    {
      image: Benefit1Img,
      bgImage: Benefit1BgImg,
      title: "Improves critical thinking and problem-solving skills",
      description: "",
      linkText: "",
      delay: "400",
      animationData: problem
    },
    {
      image: Benefit2Img,
      bgImage: Benefit2BgImg,
      title: "Boosts memory and concentration",
      description: "",
      linkText: "",
      delay: "700",
      animationData: memory
    },
    {
      image: Benefit3Img,
      bgImage: Benefit3BgImg,
      title: "Enhances creativity and imagination",
      description: "",
      linkText: "",
      delay: "800",
      animationData: creative
    },
    {
      image: Benefit4Img,
      bgImage: Benefit4BgImg,
      title: "Boosts confidence and self-esteem",
      description: "",
      linkText: "",
      delay: "1000",
      animationData: confidence
    },
  ],
};

export const coursesData = {
  subtitle1: "More than just moves, we teach strategy! ",
  subtitle2: "We'll cover a variety of topics in the class.",
  list: [
    {
      title: "Beginner ",
      description: "Beginner level course",
      price: 450,
      currency: "USD",
      classes: "for 30 classes",
      sessionPrice: 15,
      features: [
        "Foundational Knowledge",
        "Interactive Learning",
        "Tailored Feedback",
        "PTM after 8 classes",
        "Summary after each class",
        "Tournament chess kits",
        "Anytime refund policy",
      ],
      cta: "Book a demo",
      delay: "300",
      syllabus: "syllabus.pdf",
    },
    {
      title: "Intermediate",
      description: "Intermediate level course",
      price: 600,
      currency: "USD",
      sessionPrice: 20,
      classes: "for 30 classes",
      features: [
        "Advanced Tactics",
        "Diverse Scenarios",
        "Specialized Guidance",
        "PTM after 8 classes",
        "Summary after each class",
        "Tournament chess kits",
        "Anytime refund policy",
      ],
      cta: "Book a demo",
      delay: "300",
      syllabus: "syllabus.pdf",
    },
    {
      title: "Advanced - 1",
      description: "Advanced level course",
      price: 750,
      currency: "USD",
      sessionPrice: 25,
      classes: "for 30 classes",
      features: [
        "Masterclass Sessions",
        "Competitive Edge",
        "Comprehensive Analysis",
        "PTM after 8 classes",
        "Summary after each class",
        "Tournament chess kits",
        "Anytime refund policy",
      ],
      cta: "Book a demo",
      delay: "300",
      syllabus: "syllabus.pdf",
    },
    {
      title: "Advanced - 2",
      description: "Advanced level course",
      price: 750,
      currency: "USD",
      sessionPrice: 25,
      classes: "for 30 classes",
      features: [
        "Masterclass Sessions",
        "Competitive Edge",
        "Comprehensive Analysis",
        "PTM after 8 classes",
        "Summary after each class",
        "Tournament chess kits",
        "Anytime refund policy",
      ],
      cta: "Book a demo",
      delay: "300",
      syllabus: "syllabus.pdf",
    },
  ],
};

export const coachesData = [
  {
    image: RathishImg,
    name: "Rathish R",
    web: "International Chess Player (FIDE)",
    message: "Contact - 8637442848",
    delay: "300",
  },
  {
    image: SamarkshImg,
    name: "Samaksh",
    web: "International Chess Player (FIDE)",
    message: "Contact - 7767942420",
    delay: "600",
  },
  {
    image: LakshayImg,
    name: "Lakshay",
    web: "International Chess Player (FIDE)",
    message: "",
    delay: "600",
  },
];

export const ctaData = {
  title: "Young Visionaries Chess Club",
  subtitle:
    "Address : A331, Wing 6, Innovative Oak Garden, Bhoganhalli, Bengaluru, Karnataka 560103",
  btnText1: "",
  btnText2: "Book a free Demo",
  logo: Logop3,
};

export const footerData = {
  logo: Logop3,
  address: "",
  email: "",
  phone: "",

  socialList: [
    {
      icon: <FaEnvelope />,
      href: "#",
      delay: "900",
      mailid: "chessforkids@youngvisionarieschessclub.com",
      wappnum: "919344701084",
    },
  ],
};

export const copyrightData = {
  // text: "© Product Texas, 2022. All rights reserved. Company Registration Number: 09833888.",
  icon: <BsChatDotsFill />,
};

export const testimonialData = {
  parentData: [
    {
      name: "Michael Schrute",
      title: " Student - Grade 11",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },

    {
      name: "Jenny Wilson",
      title: " Student - Grade 6",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },

    {
      name: "Jacob Jones",
      title: " Student - Grade 10",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },
  ],
  studentData: [
    {
      name: "Michael Schrute",
      title: " Student - Grade 11",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },

    {
      name: "Jenny Wilson",
      title: " Student - Grade 6",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },

    {
      name: "Jacob Jones",
      title: " Student - Grade 10",
      avatar:
        "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png",
      comment:
        "You made it so simple. My new site is so much faster and easier to work with than my old site. I just choose the page, make the change.",
    },
  ],
};

export const syllabus = [
  {
    name: "Chess Beginner",
    price: 450,
    pricePerSession: 15,
    numberOfClasses: 30,
    keyFeatures: [
      "Foundational Knowledge",
      "Interactive Learning",
      "Tailored Feedback",
      "PTM AFTER 8 CLASSES",
      "Summary after each class",
      "Tournament chess kits",
      "Anytime refund policy",
    ],
    syllabus: [
      {
        mainTopic: "Introduction and Basics",
        sessions: {
          1: "Introduction to Chess - Overview, history, and objective.",
          2: "The Chessboard - Naming squares and setting up the board.",
          3: "Movement of Major Pieces - Rook, Bishop, Queen.",
          4: "Movement of Minor and Special Pieces - Knight, King, Pawn.",
          5: "Test Review - 1 - Recap of movements and basic rules.",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Attack and Defense",
        sessions: {
          6: "Basic Attacks - Using Rook, Bishop, Queen.",
          7: "Advanced Attacks - Using Knight, Pawn, targeting the King.",
          8: "Defensive Techniques - Moving pieces away, supporting them.",
          9: "Advanced Defense - Interposing and capturing.",
          10: "Test Review - 2 - Assessment of offense and defense.",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Special Moves & Mate Concepts",
        sessions: {
          11: "Special Moves - Castling, En passant.",
          12: "Check, Checkmate, Stalemate - Understanding differences.",
          13: "Mate in One - Using Queen, Rook, Minor Pieces, Pawn.",
          14: "Advanced Mates - Double Rook, Queen-Rook checkmates.",
          15: "Test Review - 3 - Concepts of mating, special moves.",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Tactics and Strategy",
        sessions: {
          16: "Tactical Play - Pin, Double Attack, Gaining by Exchange.",
          17: "Advanced Tactics - Discovered Attack, Check, Double Check.",
          18: "Pawn Strategies - Promotion, Drawing techniques.",
          19: "Introduction to Openings - Basic principles.",
          20: "Test Review - 4 - Assessment on tactics, strategies.",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Application and Practice",
        sessions: {
          21: "Simul - Opening Principle Revision - Practical application.",
          22: "Creating a Mating Threat - Recognizing opportunities.",
          23: "Notation - Basics of chess notation.",
          24: "Review of Special Moves & Rules.",
          25: "Test Review - 5 - Comprehensive assessment.",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Advanced Practice & Tournaments",
        sessions: {
          26: "In-Class Tournament - Friendly competition.",
          27: "Game Analysis - Feedback from tournament games.",
          28: "One-on-One Session with Educator - Personalized feedback.",
          29: "Strategic Play - Planning multiple moves ahead.",
          30: "Final Review & Feedback - Course recap, feedback.",
        },
        resources: "https://www.google.com/"
      },
    ]
  },
  {
    name: "Chess Intermediate",
    price: 600,
    pricePerSession: 20,
    numberOfClasses: 30,
    keyFeatures: [
      "Advanced Tactics",
      "Diverse Scenarios",
      "Specialized Guidance",
      "PTM AFTER 8 CLASSES",
      "Summary after each class",
      "Tournament chess kits",
      "Anytime refund policy",
    ],
    syllabus: [
      {
        mainTopic: "Module 1 - Tactical Foundations",
        sessions: {
          1: "Introduction Assessment",
          2: "PIN – Simple",
          3: "Discovered Attack",
          4: "Discovered Check/ Double Check",
          5: "Skewer / Xray",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 2 - Advanced Attacks and Defense",
        sessions: {
          6: "Test Review - 1",
          7: "Double Attack - Bishop, Rook",
          8: "Double Attack - Queen, Pawn",
          9: "Double Attack - Knight",
          10: "Defending Against Mate",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 3 - Enhancing Game Strategy",
        sessions: {
          11: "Capturing the Defender",
          12: "Test Review - 2",
          13: "Opening Principles – Part 1",
          14: "Simplification – Part 1",
          15: "Queen Check Mate",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 4 - Positional Play",
        sessions: {
          16: "Cross Pin",
          17: "Back Rank",
          18: "Test Review - 3",
          19: "Counter Attack",
          20: "Pawn Break Through",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 5 - Endgame Concepts",
        sessions: {
          21: "Stalemate",
          22: "Overloaded Pieces",
          23: "Key Square – Opposition",
          24: "Test Review - 4",
          25: "Demolition of Pawn Structure",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 6 - Advanced Strategies",
        sessions: {
          26: "Clearance",
          27: "Two Rooks on 7th Rank",
          28: "Under Promotion",
          29: "Drawing Combination",
          30: "Test Review - 5",
        },
        resources: "https://www.google.com/"
      },
    ],
  },
  {
    name: "Chess Advanced 1",
    price: 750,
    pricePerSession: 25,
    numberOfClasses: 30,
    keyFeatures: [
      "Masterclass Sessions",
      "Competitive Edge",
      "Comprehensive Analysis",
      "PTM AFTER 8 CLASSES",
      "Summary after each class",
      "Tournament chess kits",
      "Anytime refund policy",
    ],
    syllabus: [
      {
        mainTopic: "Module 1 - Endgame Mastery",
        sessions: {
          1: "Queen VS Pawn on 7th Rank - Theory and Exercises",
          2: "Initiative and Activity of Pieces - Classical Game",
          3: "King and Pawn Endgame Part 1 - Theory",
          4: "King and Pawn Endgame Part 2 - Examples/Exercises",
          5: "King and Pawn Endgame Part 3 - Mined Squares, Corresponding Squares",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 2 - Advanced Tactics & Strategy",
        sessions: {
          6: "King and Pawn Endgame Part 4 - Triangulation, Zungzwang",
          7: "Attacking the King - Theory (Sacrifice on h7 and g7, Uncastled King)",
          8: "Attacking the Castled King - Comprehensive (Same side & Opposite side)",
          9: "Lead in Development - Classical Game",
          10: "Pawn Play Overview - Storming, Outposts, Active Defense",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 3 - Deep Dive into Endgames",
        sessions: {
          11: "King and Pawn Endgame - Passes Pawn, Dynamic vs Static",
          12: "Endgames - Bishop vs Pawns and Rook vs Pawns",
          13: "Endgames - Bishop Challenges (Good vs Bad, Opposite Color, vs Rook)",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 4 - Understanding Pawn Structures",
        sessions: {
          14: "Pawn Structures 1 - Backward, Isolated, Hanging Pawns",
          15: "Pawn Structures 2 - Pawn Wedge, Connected, Double Pawns",
          16: "Pawn Structures 3 - Pawn Nail, Pawn Chain",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 5 - Deep Dive into Openings",
        sessions: {
          17: "Opening Deep Dive 1 - Ruylopez with Continuation",
          18: "Opening Deep Dive 2 - French Defense with Continuation",
          19: "Opening Deep Dive 3 - Caro-Kann Defense with Continuation",
          20: "Opening Deep Dive 4 - Sicilian Defense with Continuation",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 6 - Revision, Analysis, and Special Topics",
        sessions: {
          21: "Revision/Review - Endgame Focus",
          22: "Revision/Review - Attack and Defense",
          23: "Revision/Review - Pawn Play and Structures",
          24: "Revision/Review - Openings",
          25: "Practical Game Analysis 1",
          26: "Practical Game Analysis 2",
          27: "Special Topics - Chosen based on student need",
          28: "Special Topics - Continuation or a New Topic",
          29: "Group Sessions - In-class Tournaments/Game Analysis",
          30: "One on One Session - Game Analysis & Feedback",
        },
        resources: "https://www.google.com/"
      },
    ],
  },
  {
    name: "Chess Advanced 2",
    price: 750,
    pricePerSession: 25,
    numberOfClasses: 30,
    keyFeatures: [
      "Masterclass Sessions",
      "Competitive Edge",
      "Comprehensive Analysis",
      "PTM AFTER 8 CLASSES",
      "Summary after each class",
      "Tournament chess kits",
      "Anytime refund policy",
    ],
    syllabus: [
      {
        mainTopic: "Module 1 - Mastering Openings Part 1",
        sessions: {
          1: "Ruylopez",
          2: "Sicilian Najdorf - Comprehensive",
          3: "Kings Indian & Nimzo Indian",
          4: "Revision/Review - 1",
          5: "Catalan & Guicco Piano",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 2 - Mastering Openings Part 2",
        sessions: {
          6: "English Opening & French",
          7: "Carokann & Center Counter",
          8: "Revision/Review - 2",
          9: "King's Gambit & Queens Indian",
          10: "Grunfeld, Benoni & Benko",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 3 - Deep Dive into Middle Games",
        sessions: {
          11: "Slav & Semislav",
          12: "Revision/Review - 3",
          13: "Scotch Game & Game Analysis",
          14: "Bishop and Knight Check Mate & Candidate Moves",
          15: "Dragging the King Out & Mobilizing All Pieces (Middle Game)",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 4 - Advanced Strategy and Tactics",
        sessions: {
          16: "Revision/Review - 4",
          17: "Maneuvering Pieces & Central Majority",
          18: "Flank Majority & Prophylaxis",
          19: "Practical Rook Endgame & Undermining the Pawn Center",
          20: "Revision/Review - 5",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 5 - Endgame Nuances and Strategies",
        sessions: {
          21: "Queen and Pawn Endgames & Activity of King in Endgame",
          22: "Knight vs Bishop (Closed Middle Games) & Knight Endgames",
          23: "Principle of Two Weakness & Game Practice",
          24: "Revision/Review - 6",
        },
        resources: "https://www.google.com/"
      },
      {
        mainTopic: "Module 6 - Comprehensive Analysis & Advanced Strategies",
        sessions: {
          25: "Sicilian Part 2 - Comprehensive",
          26: "Game Analysis - Comprehensive",
          27: "Continuation & Advanced Strategies",
          28: "Revision/Review - 7",
          29: "Advanced Opening and Strategy Analysis",
          30: "Revision/Review - Comprehensive Finale",
        },
        resources: "https://www.google.com/"
      },
    ],
  },
];
