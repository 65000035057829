import * as React from "react";

const AppContext = React.createContext();

function AppContextProvider({ children }) {
  const [isSignedIn, setIsSignedIn] = React.useState(sessionStorage.getItem('login'));
  const [userType, setUserType] = React.useState(sessionStorage.getItem('utype'));
  const [userName, setUserName] = React.useState("");

  return (
    <AppContext.Provider
      value={{
        isSignedIn,
        setIsSignedIn,
        userType,
        setUserType,
        userName,
        setUserName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
}

export { AppContextProvider, useAppContext };
