import React from "react";
import { useState, useEffect, useContext } from "react";
import { Modal } from "@mui/material";
import Loading from "../../components/loading";
import Axios from "axios";
import Swal from "sweetalert2";

import { useLocation } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";
import { syllabus } from "../../data";

import {Box} from "@mui/material";
import DropDowm from "../../components/Input/DropDown";
import { ParentContext } from "../../context/ParentContext";
const ViewCourseProgress = () => {
  const { userType } = useAppContext();
  const { state } = useLocation();
  let student = 0;

  // const beginnerData = [
  //   {
  //     title: "Introduction and Basics",
  //     sessionData: [
  //       {
  //         id: 1,
  //         title: "Introduction to Chess - Overview, history, and objective.",
  //       },
  //       {
  //         id: 2,
  //         title: "The Chessboard - Naming squares and setting up the board.",
  //       },
  //       {
  //         id: 3,
  //         title: "Movement of Major Pieces - Rook, Bishop, Queen.",
  //       },
  //       {
  //         id: 4,
  //         title: "Movement of Minor and Special Pieces - Knight, King, Pawn.",
  //       },
  //       {
  //         id: 5,
  //         title: "Test Review - 1 - Recap of movements and basic rules.",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Attack and Defense",
  //     sessionData: [
  //       {
  //         id: 6,
  //         title: "Basic Attacks - Using Rook, Bishop, Queen.",
  //       },
  //       {
  //         id: 7,
  //         title: "Advanced Attacks - Using Knight, Pawn, targeting the King.",
  //       },
  //       {
  //         id: 8,
  //         title: "Defensive Techniques - Moving pieces away, supporting them.",
  //       },
  //       {
  //         id: 9,
  //         title: "Advanced Defense - Interposing and capturing.",
  //       },
  //       {
  //         id: 10,
  //         title: "Test Review - 2 - Assessment of offense and defense.",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Special Moves & Mate Concepts",
  //     sessionData: [
  //       {
  //         id: 11,
  //         title: "Special Moves - Castling, En passant.",
  //       },
  //       {
  //         id: 12,
  //         title: "Check, Checkmate, Stalemate - Understanding differences.",
  //       },
  //       {
  //         id: 13,
  //         title: "Mate in One - Using Queen, Rook, Minor Pieces, Pawn.",
  //       },
  //       {
  //         id: 14,
  //         title: "Advanced Mates - Double Rook, Queen-Rook checkmates.",
  //       },
  //       {
  //         id: 15,
  //         title: "Test Review - 3 - Concepts of mating, special moves.",
  //       },
  //     ],
  //   },
  // ];
  const parentState = useContext(ParentContext);

  if (userType === "parent") {
    student = parentState.currentStudent
  } else if (state) {
    student = state.student
  }

  // console.log(student)

  // console.log(student)

  const [pickCourseOpen, setPickCourseOpen] = useState(false);

  const [courseIdData, setCourseIdData] = useState([]);
  const [courseID, setCourseID] = useState(null);

  useEffect(() => {
    if (courseID == null) {
      fetchCourseID();
    }
    // fetchData();
  }, []);

  const fetchCourseID = async () => {
    try {
      setLoadingOpen(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/${userType}/courseDetails/${student}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );

      if (response.status == 201) {
        setCourseIdData(response.data.result);
      }
      setPickCourseOpen(true);
    } catch (err) {
      if (!err.response) {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  const [courseData, setCourseData] = useState();
  const [moduleProgress, setModuleProgress]  = useState([])

  const [loadingOpen, setLoadingOpen] = useState(true);

  const fetchData = async () => {
    try {
      setLoadingOpen(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/${userType}/modulePercentage/${courseID}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }
      );
     

      if (response.status == 201) {
        
        console.log(response.data.result)
        setCourseData(
          syllabus.filter((obj) => obj.name.toLowerCase() === response.data.result.courseName.toLowerCase())[0]
        );
        setModuleProgress(response.data.result.percentage)
        // console.log(courseData['syllabus'])
        // console.log(syllabus.filter((obj) => obj.name.toLowerCase() === response.data.result.courseName.toLowerCase()));
        setLoadingOpen(false);
      }
    } catch (err) {
      setLoadingOpen(false);

      if (!err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  return (
    <div className="bg-[#fbf7ff] w-full min-h-full flex flex-col items-center p-10">
      <div className="flex flex-col w-5/6 gap-24">
        <div className=" inline-flex flex-row justify-between">
          <span className=" self-center text-7xl font-bold text-primary font-primary">
            Course
            <br />
            Progress
          </span>

          <div></div>
        </div>
        {!loadingOpen && (
          <div class="timeline">
            {courseData['syllabus'].map((mod, idx) => (
              <>
                <div
                  class={`timeline__event  animated fadeInUp delay-3s timeline__event--type${
                    (idx % 3) + 1
                  }`}
                >
                  <div class="timeline__event__icon ">
                    {/* <!-- <i class="lni-sport"></i>--> */}
                    <div id="loader" class="ball">
                      <div
                        class={`delay wave${(idx % 3) + 1}`}
                        style={{
                          background: `url('https://i.imgur.com/uFpLbYt.png')`,
                          backgroundSize: `200px ${
                            moduleProgress[idx % 3] * 100
                          }px`,
                          backgroundRepeat: "repeat-x",
                        }}
                      >
                        <div
                          class={`delay wave${(idx % 3) + 1}`}
                          style={{
                            background: `url('https://i.imgur.com/uFpLbYt.png')`,
                            backgroundSize: `200px ${
                              moduleProgress[idx % 3] * 100
                            }px`,
                            backgroundRepeat: "repeat-x",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="timeline__event__date">Module {idx + 1} <br/> {moduleProgress[idx % 3]*100}%</div>
                  <div class="timeline__event__content ">
                    <div class="timeline__event__title">{mod.mainTopic}</div>
                    <div class="timeline__event__description">
                      {Object.entries(mod.sessions).map((sess) => (
                      <>
                        <br></br>
                        <p>
                          <b>Session {sess[0]}:</b> {sess[1]}
                        </p>
                      </>
                    ))}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      </div>

      <Modal open={loadingOpen}>
        <Loading />
      </Modal>

      {pickCourseOpen && (
        <Modal
          open={pickCourseOpen}
          onClose={() => setPickCourseOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              justifyItems: "center",
              height: "30%",
              width: "30%",
              backgroundColor: "white",
            }}
            className="rounded-lg flex flex-col gap-2 pl-20 pb-20  pt-20"
          >
            <span class="text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl font-bold text-primary font-primary">
              Select course
            </span>
            <br />

            <div className="w-3/6">
              <DropDowm data={courseIdData} handleDropChange={setCourseID} />
            </div>

            <button
              className="mt-6 w-24 text-white bg-primary  hover:bg-accent-secondary_hover font-bold py-2 px-4 rounded"
              onClick={() => {
                setPickCourseOpen(false)
                fetchData()
              }}
            >
              Confirm
            </button>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ViewCourseProgress;
