import React, { useEffect } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AlertDialog from "./AlertDialog";
import { useState } from "react";
import { Modal } from "@mui/material";
import BookClass from "./BookClass";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

import dayjs, {Dayjs} from "dayjs";
const Module = ({ data, utype, courseID }) => {
  // const moduleData = [
  //   {
  //     sessionId: 1,
  //     title: "Introduction to chess",
  //     description: "Getting started with chess!!",
  //     status: "In progress",
  //     resources: "https://www.google.com/",
  //     classDetails: [
  //       {
  //         classID: 1,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //       {
  //         classID: 2,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //       {
  //         classID: 3,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //     ],
  //   },
  //   {
  //     sessionId: 2,
  //     title: "Introduction to chess",
  //     description: "Getting started with chess!!",
  //     status: "In progress",
  //     resources: "https://www.google.com/",
  //     classDetails: [
  //       {
  //         classID: 4,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //       {
  //         classID: 5,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //       {
  //         classID: 6,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //     ],
  //   },
  //   {
  //     sessionId: 3,
  //     title: "Introduction to chess",
  //     description: "Getting started with chess!!",
  //     status: "In progress",
  //     resources: "https://www.google.com/",
  //     classDetails: [
  //       {
  //         classID: 7,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //       {
  //         classID: 8,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //       {
  //         classID: 9,
  //         classDate: "27/11/2023",
  //         classDuration: "90",
  //         instructorFdbk: "Navaneeth sucks at chess",
  //         studentFdbk: "IKR, he does suck at chess...",
  //       },
  //     ],
  //   },
  // ];

  // const [moduleData, setModuleData] = useState(data)

  // console.log(data);
  const moduleData = data;

  const [openBookClass, setOpenBookClass] = useState(false);
  const [sessID, setSessID] = useState(0);

  const [isEdit, setEdit] = useState(false)
  const [currClass, setCurrClass] = useState({
    classID: 0,
    classDate: dayjs(Date.now()),
    classDuration: 90,
    edit: false,
    courseID: courseID
  })


   return (
    <div className=" w-full mt-8">
      {/* <div>Hellow</div> */}
      {moduleData &&
        moduleData.map((obj, i) => (
          <Accordion className="w-full">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="flex flex-row justify-start gap-2">
                <span>
                  Session {obj.sessionId}: {obj.title}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div class="m-2 p-5 border-b border-gray-200">
                <p class="mb-2 text-black">{obj.description}</p>
                <div class="flex justify-between mb-2">
                  <span class="text-gray-500 italic">Status: {obj.status}</span>
                  <a
                    href={obj.resources}
                    class="font-medium text-purple-700 hover:underline"
                  >
                    Resources
                  </a>
                </div>

                <div className=" flex flex-col gap-2 ">
                  {obj.classDetails.map((classObj) => (
                    <div className="flex justify-between gap-2 p-4 rounded-md border-primary border shadow-sm bg-[#fbf7ff]">
                      <div className="flex justify-start ">
                        {utype === "instructor"? (<Tooltip title="Edit class">
                          <EditIcon className="text-primary" sx={{ fontSize: "1rem !important" }} onClick={() => {
                            setCurrClass({classID: classObj.id, classDate: dayjs(new Date(classObj.classDate)), duration: classObj.classDuration, sessionId: obj.sessionId, edit: true})
                            setOpenBookClass(true)
                          }}  />
                        </Tooltip>) : <></>}
                        <span className=" font-primary text-primary">
                          Class ID: {classObj.id} &nbsp; &nbsp;{" "}
                        </span>
                        <span className=" font-primary text-primary">
                          Date:{" "}
                          {new Date(classObj.classDate)
                            .toISOString()
                            .replace(/T/, "-")
                            .replace(/\..*/, "")}
                          &nbsp; &nbsp;{" "}
                        </span>
                        <span className=" font-primary text-primary">
                          Duration: {classObj.classDuration} min
                        </span>
                      </div>
                      <div className="flex justify-end flex-row gap-2">
                        <AlertDialog
                          className="bg-primary"
                          title="Instructor Feedback"
                          content={classObj.instructorFeedback}
                          allowEdit={utype === "instructor"}
                          classID={classObj.id}
                        />
                        <AlertDialog
                          className="bg-primary"
                          title="Parent Feedback"
                          content={classObj.studentFeedback}
                          allowEdit={utype === "parent"}
                          classID={classObj.id}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-row w-full  justify-center mt-8">
                  {utype === "instructor" && obj.status !== "Completed" ? (
                    <button
                      variant="contained"
                      className="bg-primary text-white  text-sm p-1 rounded-md hover:border-purple-700 hover:bg-accent-primary_hover w-25 h-10"
                      onClick={() => {
                        setSessID(obj.sessionId);
                        setOpenBookClass(true);
                        setCurrClass((curr) => ({...curr, edit: false}))
                      }}
                    >
                      Create Class
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}

      {openBookClass && (
        <Modal
          open={openBookClass}
          onClose={() => setOpenBookClass(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "scroll"
          }}
        >
          <BookClass sessionID={sessID} currClass={currClass} />
        </Modal>
      )}
    </div>
  );
};

export default Module;
