import React, { useEffect } from "react";
import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

import { Modal } from "@mui/material";
import Loading from "../../components/loading";
import Axios from "axios";
import Swal from "sweetalert2";

const ManageIns = () => {

  const navigate = useNavigate()
  const usrData = [
    {
      id: "1",
      name: "John Doe",
      mobile: "9243924",
      email: "dwdad",
      studentCount: "12",
    },
    {
      id: "2",
      name: "Sarah Doe",
      mobile: "9243924",
      email: "dwdad",
      studentCount: "12",
    },
    {
      id: "3",
      name: "Stella Doe",
      mobile: "9243924",
      email: "dwdad",
      studentCount: "12",
    },
    {
      id: "4",
      name: "David Doe",
      mobile: "9243924",
      email: "dwdad",
      studentCount: "12",
    },
    {
      id: "5",
      name: "Jane Doe",
      mobile: "9243924",
      email: "dwdsdsad",
      studentCount: "12",
    },
  ];

  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(usrData);
  const [loadingOpen, setLoadingOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    try {
      setLoadingOpen(true);
      let response = await Axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/admin/getInstructorSignup`, {
        headers: {
          token: sessionStorage.getItem("token"),
        },
      });
      setLoadingOpen(false);

      if (response.status == 201) {
        setData(response.data.result);
      }
    } catch (err) {
      setLoadingOpen(false);

      if (!err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't reach server",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }
    }
  };

  // exclude column list from filter
  const excludeColumns = [];

  // handle change event of search input
  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(usrData);
    else {
      const filteredData = usrData.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  return (
    <div className="bg-[#fbf7ff] w-full min-h-full flex flex-col items-center p-10">
      <div className="flex flex-col w-5/6 gap-24">
        <div className=" inline-flex flex-row justify-between">
          <span className=" self-center text-7xl font-bold text-primary font-primary">
            Manage
            <br />
            Instructors
          </span>
        </div>

        <div className="flex flex-row justify-center">
          <input
            type="search"
            className="relative m-0 block w-5/6  min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700  focus:outline-none"
            placeholder="Search by ID, name, email, mobile"
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        <div>
          {data.map((obj) => (
            <Accordion className="w-full">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="flex flex-row justify-start gap-2">
                  <span className=" font-semibold text-primary text-lg">
                    {obj.id} {obj.name}
                  </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="px-4 flex flex-col gap-2">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-2">
                      <span className=" font-semibold text-primary">ID:</span>
                      <span className="text-neutral-700">{obj.id}</span>
                    </div>
                    <div className="flex flex-row gap-2">
                      <span className=" font-semibold text-primary">Name:</span>
                      <span className="text-neutral-700">{obj.name}</span>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row gap-2">
                      <span className=" font-semibold text-primary">
                        Email:
                      </span>
                      <span className="text-neutral-700">{obj.email}</span>
                    </div>
                    <div className="flex flex-row gap-2">
                      <span className=" font-semibold text-primary">
                        Mobile:
                      </span>
                      <span className="text-neutral-700">{obj.mobile}</span>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center">
                  <button
                    className=" rounded-md bg-primary font-semibold text-white w-44 h-[38px] hover:bg-[#fbf7ff] hover:text-primary hover:border-primary hover:border-2 hover:rounded-tl-lg"
                    onClick={() => navigate("/admin/manage-student", {state: {instructor: obj.id}})}
                  >
                    View Students
                  </button>
                  </div>
                 
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <Modal open={loadingOpen}>
        <Loading />
      </Modal>
    </div>
  );
};

export default ManageIns;
