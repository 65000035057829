// Utility function to generate excel from array of objects
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";


export const exportExcel = async (excelData, usrType) => {
  // const serviceAccountAuth = new JWT({
  //   // env var values here are copied from service account credentials generated by google
  //   // see "Authentication" section in docs for more info
  //   email: "young-visionaries@youngvisionaries.iam.gserviceaccount.com",
  //   key:
  //     "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDXHPcA8HDiYWPn\nm+zFiU0Y2Fchs+jzZ1QF+9tF6OSx2rYdObLKHSW35Fcbd2EIsd9ToA/jv1bmQclg\nz6U1g4dRyLa2xk2GOVxuBJAfKRoB/XUTMUjKvWpSl9ENeCQm6+FekkG7fIlJbp6B\nBMuOTl+4JUNTlOzlQCDYwXEpFPl4UTOlmXlnCDctL8JMtv8L/p5e/ffW1Gom1eVY\nKLp3WfYJfdxfnZnqDC3s4USyUAC5Okf4Rh0HTxWKbNEJMS0E8KXDkoFlIDZfs8FW\nTOj2YleX8C/DmFP82w2PWrwH2njF9sFlJZAK4nP5oSXIHXEwofT9cGhO6ONkAvRh\nKFYGedG7AgMBAAECggEABOigzFEwSkItrPHWb9WHwFrjdh3sYW2IsdBC+y/q1BIp\nGLah2VHb8/vObw6E1NV2QqrC4hASNBPpO//RBc0YD+o0HSyeRUNW1B3fig5+jPAT\nNVppDlNPM05Kk3Ob45V7YZSV0mjvmxDephQNbxRjw8h55lTeKQcTGM8lPWpAnRm2\njZQsiwrd0NuAKfCR4mjpxKel/SZLsUDIdsxrPe9wP7pckR37zIepeqxyRODn9jY3\nF9r4SMWz5QO7OuhufJxphQbhRMefaFxzftK9M5bVQRfCzc4EL3I5BKLFUvaz6hQe\nc11DwKMrBIOWA7SJ+edv5a9MDx+NsCrgOvi7pwd2fQKBgQDtLpIcVvWixGZmPHcA\nDQpgnavDY3kbRS8tVvZvh9rpB9+RcjJUl9gXOqL0icyhrm+Vwx+xTw657u7ZNOd+\nJUaB2bWAl2ZLjnd8bTG3qHbSiA4nFbhdcOiGHM8gv4RAGot62l5vQvTR3spgHb1Z\nGV9d0XL1ZYRFixphDVDsNopk7QKBgQDoLicYuY5z7q5PPDQLJzdQOyFO7rqY0q1b\njat7O7nnvwKjsCC8ddDNdZKhEiToORDT6wSspCPT6O32sVbhyuIt8CDpPS7jCAmJ\n71Bs54xe0OLSZ2Eex8pkuNnXwS94TOv/gVN5z7sF3yo3tRCiq8YXR4RMEUI93OuW\nsQ4rdTCkRwKBgQCp6Ea7DoCAzXkdnAsTekQI2mqFWekT5AfbzwRS+VuUvBMoOkpb\n1zrxyQubamuxFyjQfCtj9WJzR/rKlt+Oul08AB3JtKuPTMvD0uVez8vvHZAl56Vf\nKtcn29N1fjzbPyjUJUKpKan//j2zhRpzjX2H09cqfEK5HuESzVs/HAMgMQKBgAnZ\nS1dFJ8HLMiGyUBLZCwOgPn12qvuolCn6DHghxHUMKTSAWCMmbEEK57llZ6NiKFCU\n+5HHNK3ch5ubUjApxwZj1UGPktmWkbEEMcks2Ut8WOLL99ayaudy3+rDCxxzRt+D\nwOK96RUKrmPGjnoYB0MdiYMgiErPgD7YK920DugpAoGBAKC1MRZOE6Z03/Jv9Nr0\ne6JXGTXDhiawesrQO84xL0/tj2oyf0jc3YyEdc9/6tueWOypnFy7kX3C2QuuZPHf\nIfZtmPcYWdjhhx6k/6X/j4HeOZ+APxnW7B0V4bIYo9HPyRhGRJqAH9Kdx+fqUu8i\nslS53LRU3HyyNB7gR+fVybUZ\n-----END PRIVATE KEY-----\n",
  //   scopes: ["https://www.googleapis.com/auth/spreadsheets"],
  // });

  // const doc = new GoogleSpreadsheet(
  //   "1KfuVb9mYJSMH5fErA5azwgK1xLYX-w3uYzUBmVE_-Ys",
  //   serviceAccountAuth
  // );

  // await doc.loadInfo();

  // const sheet = doc.sheetsByIndex[0];

  let fileName = 'demo-requests'
  if (usrType === 1)
    fileName = 'parent_registrations'
  else if (usrType === 2)
    fileName = 'admin-registrations'
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(excelData);
  const excelBuffer = XLSX.write(
    {
      Sheets: { data: ws },
      SheetNames: ["data"],
    },
    { bookType: "xlsx", type: "array" }
  );
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
